<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="box" v-loading.fullscreen.lock="fullscreenLoading">
      <export_excel :json_data="json_data" :json_fields="json_fields" name="刊物广告.xls" car_placard="广告"/>
      <placard_screen path_arr="path_array1" :category_id="3" @screen_confirm="pageChange(1)"
                      @reset_screen="pageChange(1)"/>
      <Title title="资源列表"/>
      <div class="table_box">
        <el-table :data="tableData">
          <el-table-column width="200" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="刊物名称">
            <template slot-scope="scope">
              <el_table_column column_type="text" prop="title" :scope="scope"/>
            </template>
          </el-table-column>
          <el-table-column width="100" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="广告位置">
            <template slot-scope="scope">
              <el_table_column column_type="text" prop="address" :scope="scope"/>
            </template>
          </el-table-column>
          <el-table-column width="100" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="刊物类型">
            <template slot-scope="scope">
              <el_table_column column_type="text" prop="portal_title" :scope="scope"/>
            </template>
          </el-table-column>
          <el-table-column width="100" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="行业类型">
            <template slot-scope="scope">
              <el_table_column column_type="text" prop="classify_title" :scope="scope"/>
            </template>
          </el-table-column>
          <el-table-column width="100" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="覆盖区域">
            <template slot-scope="scope">
              <el_table_column column_type="text" prop="area_title" :scope="scope"/>
            </template>
          </el-table-column>
          <el-table-column width="200" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="投放价格">
            <template slot-scope="scope">
              <el_table_column column_type="slect_money" :scope="scope" prop="member_guanggao_price"
                               @select_item="select_item"/>
            </template>
          </el-table-column>
          <el-table-column width="200" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="投放周期">
            <template slot-scope="scope">
              <el_table_column column_type="input_number" :scope="scope" @handle_cycle="handle_cycle"/>
            </template>
          </el-table-column>
          <el-table-column width="140" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="设计规格">
            <template slot-scope="scope">
              <el_table_column column_type="text" prop="design_rule" :scope="scope"/>
            </template>
          </el-table-column>
          <el-table-column width="100" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="设计单位">
            <template slot-scope="scope">
              <el_table_column column_type="text" prop="design_unit_text" :scope="scope"/>
            </template>
          </el-table-column>
          <el-table-column width="120" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="设计价格">
            <template slot-scope="scope">
              <el_table_column @handle_price_click="handle_price_click" column_type="button_money"
                               prop="design_price" :scope="scope"/>
            </template>
          </el-table-column>
          <el-table-column width="130" current-row-key="currentRowKey" label-class-name="custom-header-class"
                           class-name="custom-column-cell" header-align="center" align="center" label="备注(仅供参考)">
            <template slot-scope="scope">
              <el_table_column column_type="remark" prop="remark" :scope="scope"/>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label-class-name="custom-header-class"
                           class-name="custom-column-cell" fixed="right" align="center" width="130" label="操作">
            <template slot-scope="scope">
              <el_table_column column_type="controls" :scope="scope" @collect_status="collect_status"/>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
      </div>
      <!-- 购物车 -->
      <placard_shop_car :tableDate="tableData" :showCar="showCar" @delete_item="delete_item" @empty="empty"
                        @excelPush="excelPush"/>

    </div>
  </div>
</template>

<script>

import {mapState, mapMutations} from 'vuex'
import placard_screen from '@/components/placard_screen'
import el_table_column from '@/components/el_table_column'
import placard_shop_car from '@/components/placard_shop_car'
import {media_screen_} from '@/util/media_screen_'
import export_excel from '@/components/export_excel';
import {place_select_item} from '@/util/place_select_item'
import {place_select_check} from '@/util/place_select_check'
import {place_delete_item} from '@/util/place_delete_item'

export default {
  components: {
    export_excel,
    placard_screen,
    el_table_column,
    placard_shop_car,
  },
  computed: {
    ...mapState(['placard']),
  },
  watch: {
    tableData: {
      handler(newValue) {
        // this.$store.commit('SET_CAR_LIST', newValue)
      },
      deep: true
    }
  },
  data() {
    return {
      tableData: [],
      fullscreenLoading: false,
      total_page: 0, //分页总页数
      count: 0, //总条数
      page: 1,
      json_fields: {
        "刊物名称": 'title',
        "广告位置": 'address',
        "刊物类型": 'portal_title',
        "行业类型": 'classify_title',
        "覆盖城市": 'area_title',
        "投放价格": 'member_guanggao_price',
        "投放周期": "cycle",
        "设计规格": 'design_rule',
        "设计单位": 'design_unit_text',
        "设计价格": 'design_price',
        "备注": 'remark',
      },
      json_data: [],
      showCar: false,
    }
  },
  methods: {
    select_item(select_data) {
      place_select_item(this.tableData, select_data)
      this.showCar = true
    },
    delete_item(delete_item) {
      place_delete_item(this.tableData, delete_item)
    },
    handle_cycle(currentValue, index) {
      this.tableData[index].cycle = currentValue
    },
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    handle_price_click(index) {
      place_select_check(this.tableData, index)
      this.showCar = true
    },
    collect_status(index, status) {
      this.tableData[index].is_collect = status
    },
    empty() {
      this.tableData.forEach((item, index) => {
        this.tableData[index].check = false
        this.tableData[index].type = 0
        this.tableData[index].cycle = 1
      })
    },
    // 获取列表数据
    get_list() {
      this.fullscreenLoading = true
      let data = media_screen_(this.placard.date_form)
      data.page = this.page
      data.limit = 20
      this.curlGet('/api/guanggao_info/list', data).then(res => {
        if (res.data.code) {
          this.fullscreenLoading = false
          this.tableData = res.data.data.list
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData.forEach((item, index) => {
            this.$set(this.tableData[index], 'check', false)
            this.$set(this.tableData[index], 'type', 0)
            this.$set(this.tableData[index], 'cycle', 1)
            if (this.placard.car_list.length != 0) {
              this.placard.car_list.forEach((el, i) => {
                if (this.tableData[index].id == this.placard.car_list[i].id) {
                  this.tableData[index] = this.placard.car_list[i]
                }
              })
            }
          })
          // this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
        }
        this.fullscreenLoading = false
      })

    },
    // 筛选出excel表中所要的数据
    excelPush(list) {
      this.json_data = []
      list.forEach((item, index) => {
        this.json_data.push({
          title: item.title,
          address: item.address,
          portal_title: item.portal_title,
          classify_title: item.classify_title,
          area_title: item.area_title,
          member_guanggao_price: `${item.member_guanggao_price[0]}/天,${item.member_guanggao_price[1]}/周,${item.member_guanggao_price[2]}/月,${item.member_guanggao_price[3]}/次,${item.member_guanggao_price[4]}/期`,
          cycle: item.cycle,
          design_rule: item.design_rule,
          design_unit_text: item.design_unit_text,
          design_price: item.design_price,
          remark: item.remark,
        })
      })
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/placard_media';
</style>